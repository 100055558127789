// Skills icons - https://icon-sets.iconify.design/
import { Icon } from "@iconify/react";

// Navbar Logo image (add your image to the src/images directory and uncomment the line below to import your image)
//import newLogo from "./images/logos--internet-computer-icon.svg"

// Hero Images (add your images to the /images directory with the same names)
import HeroLight from "./images/hero-light2.jpg";
import HeroDark from "./images/hero-dark2.jpg";

// Projects Images (add your images to the images directory and import below)
import Logo from "./images/logos--internet-computer-icon.svg";

/* START HERE
 **************************************************************
  Add your GitHub username (string - "YourUsername") below.
*/
export const githubUsername = "Nithusan2002";

// Navbar Logo image
export const navLogo = null;

/* Main
 ************************************************************** 
  Add a custom blog icon or update the hero images for the Main section.
*/
export const Blog = null;

// Hero images (imported above - lines 8-9)
export { HeroLight as Light };
export { HeroDark as Dark };

/* About Me
 **************************************************************
  Add a second paragraph for the about me section.
*/
export const moreInfo =
  "Jeg er en dedikert bachelorstudent som studerer Informatikk: Programmering og systemarkitektur ved Universitetet i Oslo. Gjennom studiet har jeg utviklet gode ferdigheter innen programmering, datasikkerhet og systemdesign. Min erfaring som sikkerhetsvakt hos Avarn Security, kombinert med min tid som lagfører i Forsvaret, har gitt meg verdifulle lederegenskaper og evnen til å håndtere stressende situasjoner. Jeg er motivert til å benytte meg av min kompetanse og praktiske erfaring i en fremtidig karriere innen IT.";

/* Skills
 ************************************************************** 
  Add or remove skills in the SAME format below, choose icons here - https://icon-sets.iconify.design/
*/
export const skillData = [
  {
    id: 3,
    skill: <Icon icon="cib:java" className="display-4" />,
    name: "Java",
  },
  {
    id: 3,
    skill: <Icon icon="teenyicons:python-outline" className="display-4" />,
    name: "Python",
  },
  {
    id: 2,
    skill: <Icon icon="nonicons:kotlin-16" className="display-4" />,
    name: "Kotlin",
  },
  {
    id: 3,
    skill: <Icon icon="devicon-plain:android" className="display-4" />,
    name: "Android Studio",
  },
  {
    id: 3,
    skill: <Icon icon="devicon-plain:postgresql-wordmark" className="display-4" />,
    name: "Postgre SQL",
  },
  {
    id: 1,
    skill: <Icon icon="mdi:language-html5" className="display-4" />,
    name: "HTML5",
  },
  {
    id: 1,
    skill: <Icon icon="flowbite:css-solid" className="display-4" />,
    name: "CSS",
  },
  {
    id: 8,
    skill: <Icon icon="bi:git" className="display-4" />,
    name: "Git",
  },
  {
    id: 9,
    skill: <Icon icon="fa6-brands:square-github" className="display-4" />,
    name: "GitHub",
  },
];

// Resume link (string - "https://YourResumeUrl") - I am using CloudFront to share my resume (https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html)
export const cv = "https://drive.google.com/file/d/1S_-JVrVkerRn4qK4oCiTt1D_SoKlt1w2/view?usp=drive_link";

/* Projects
 ************************************************************** 
  List the repo names (string - "your-repo-name") you want to include (they will be sorted alphabetically). If empty, only the first 3 will be included.
*/
export const filteredProjects = ["IN2000-prosjekt"];

// Replace the defualt GitHub image for matching repos below (images imported above - lines 7-8)
export const projectCardImages = [
  {
    name: "example-1",
    image: Logo,
  },
];

/* Contact Info
 ************************************************************** 
  Add your formspree endpoint below.
  https://formspree.io/
*/
export const formspreeUrl = "https://formspree.io/f/xblreppv";

// Footer icons theme (light or dark)
export const footerTheme = "dark";
