import React from "react";
// Styles
import styled from "styled-components";
// State
import PropTypes from "prop-types";
// Components
import { Element } from "react-scroll";
import { Col, Container, Row } from "react-bootstrap";
import Title from "./Title";

// #region styled-components
const StyledAboutMe = styled.section`
  p {
    font-size: 1.25rem;
  }
  .img {
    width: 18rem;
    height: 18rem;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 15rem;
  height: auto;
  border-radius: 50%;
  border: 4px solid #e8e8e8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const AvatarContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
// #endregion

// #region component
const propTypes = {
  avatar_url: PropTypes.string.isRequired,
  bio: PropTypes.string,
  moreInfo: PropTypes.string,
};

const AboutMe = ({ avatar_url, bio, moreInfo }) => {
  return (
    <Element name={"About"} id="about">
      <StyledAboutMe className="section">
        <Container>
          <Container className="d-flex justify-content-center">
            <Title size={"h2"} text={"Om meg"} />
          </Container>
          <Row className="align-items-center mt-5">
            <Col md={6} className="d-flex flex-column text-center">
              <Container>
                {bio && <p>{bio}</p>}
                {moreInfo && <p>{moreInfo}</p>}
              </Container>
            </Col>
            <Col md={6}>
              <AvatarContainer>
                <StyledImage
                  src={avatar_url}
                  alt="GitHub Avatar"
                  loading="lazy"
                />
              </AvatarContainer>
            </Col>
          </Row>
        </Container>
      </StyledAboutMe>
    </Element>
  );
};

AboutMe.propTypes = propTypes;
// #endregion

export default AboutMe;
